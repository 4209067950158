import React from 'react';
import './App.css';
import Offer from './Offer';


class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "gather",
    };
  }

  startOver = () => {
    window.location.reload();
  }

  toggleMode = () => {
    this.setState({ mode: (this.state.mode === "order") ? "gather" : "order" });
  }

  render() {
    return <>
      <nav className="navbar navbar-expand-lg navbar-danger bg-warning">
        <div className="container">
        <a href="#" className="navbar-brand">Much coffee!</a>
        <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbar">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbar">
          <ul className="navbar-nav nav ms-auto">
            <li className="nav-item"><a href="#" className="nav-link active" onClick={ this.startOver }>Start over!</a></li>
          </ul>
        </div>
        </div>
      </nav>

      <div className="container">
        <div className="row row-cols-1">
          <Offer mode={ this.state.mode } titleEn="Ristretto" titleFr="Ristretto" />
          <Offer mode={ this.state.mode } titleEn="Espresso" titleFr="Espresso" />
          <Offer mode={ this.state.mode } titleEn="Coffee" titleFr="Café" />
          <Offer mode={ this.state.mode } titleEn="Coffee with milk" titleFr="Café au lait" />
          <Offer mode={ this.state.mode } titleEn="Café latté" titleFr="Café latté" />
          <Offer mode={ this.state.mode } titleEn="Cappucino" titleFr="Cappuccino" />
          <Offer mode={ this.state.mode } titleEn="Cappucino decaf" titleFr="Cappuccino decaf" />
          <Offer mode={ this.state.mode } titleEn="Tea" titleFr="Thé" />
          <Offer mode={ this.state.mode } titleEn="Hot chocolate" titleFr="Chocolat chaud" />
        </div>

        <div className="row">
          <div className="col text-center">
            <button className="btn btn-success mb-3 gap-2 fs-2" onClick={ this.toggleMode }>
              { (this.state.mode) === "order" ? "Return to gathering" : "Make an order!" }
            </button>
	        </div>
        </div>
      </div>
    </>
  }
}

export default App;
