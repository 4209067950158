import React from 'react';

class Offer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      counter: 0,
    };
  }

  counterIncrement = (e) => {
    this.setState({ counter: this.state.counter + 1 });
  }

  counterDecrement = (e) => {
    this.setState({ counter: (this.state.counter > 0) ? this.state.counter - 1 : 0 });
  }
  render() {
    return (this.props.mode === "order") ? this.renderForOrder() : this.renderForGathering();
  }

  renderForGathering = () => {
    return <div className="col d-grid gap-2">
      <div className="btn-group mb-3">
        <button className="col-11 btn btn-danger" onClick={ this.counterIncrement }>
          <div className="row g-0">
            <div className="col-4">
              <h1 className="display-1 text-center align-middle">{ this.state.counter }</h1>
            </div>
            <div className="col-8">
              <div className="d-flex align-items-middle">
                <h1>{ this.props.titleEn }</h1>
              </div>
            </div>
          </div>
        </button>
        <button type="button" className="col-1 btn btn-danger dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
          <span className="visually-hidden">Toggle Dropdown</span>
        </button>
        <ul className="dropdown-menu fs-1">
          <li><a className="dropdown-item" href="#" onClick={ this.counterDecrement } >-1</a></li>
        </ul>
      </div>
    </div>
  }

  renderForOrder = () => {
    return (!this.state.counter) ? <></> : <div className="col d-grid gap-2">
      <button className="btn btn-outline-danger mb-3">
        <div className="row g-0">
          <div className="col-4">
            <h1 className="display-1 text-center align-middle">{ this.state.counter }</h1>
          </div>
          <div className="col-8">
            <div className="d-flex align-items-middle">
              <h1>{ this.props.titleFr }</h1>
            </div>
          </div>
        </div>
      </button>
    </div>
  }
}

export default Offer;
